import { computed } from 'vue';
import { useStore } from 'vuex-composition-helpers/dist';

import { HIERARCHY, RESPONSE_STATUS } from './constants/paymentMethodsHub';

import { PaymentMethodsHubComposable } from './composables/PaymentMethodsHubComposable';
import { storage } from './composables/storage';

export const commonImplementationHelper = (providedStore, providedOperator) => {
  const store = providedStore || useStore();

  const userData = computed(() => store.getters['UserData/getUserData']).value;

  const CSOperator = providedOperator || computed(
    () => store.getters['CSOperator/getCurrentCSOperator'],
  ).value;

  const profile = computed(
    () => store.getters['Profile/getCurrentProfile'],
  ).value;

  const profileCollection = computed(
    () => store.getters['Profile/getProfileCollection'],
  ).value;

  const storeData = {
    userData,
    CSOperator,
    profile,
    profileCollection,
    locale: userData.locale,
  };

  const { getProfileInfo } = PaymentMethodsHubComposable(storeData);

  const {
    currentCSOperator, isBusinessProfile, isAdminInCompanyPays, userIsAdmin,
  } = getProfileInfo();

  const saveResponse = (response, action, hierarchy) => {
    const { parent, ancestor } = hierarchy || storage.get(HIERARCHY);

    storage.remove(HIERARCHY);

    const issuerComponent = {
      parent,
      ancestor,
      isBusinessProfile,
    };

    const status = action || response.providerStatus;

    store.dispatch('PaymentMethodsHubStore/saveResponse', {
      ...response,
      status: RESPONSE_STATUS[status],
      issuerComponent,
    });
  };

  const onAddPaymentAction = (response, action, hierarchy) => {
    saveResponse(response, action, hierarchy);
  };

  const finalStatus = [
    RESPONSE_STATUS.authorised,
    RESPONSE_STATUS.refused,
    RESPONSE_STATUS.error,
  ];

  const checkPaymentIsFinalStatus = response => (finalStatus.includes(response.providerStatus));

  const setNewResponse = (response, action, hierarchy) => {
    const hubPayment = {
      isHubPayment: true,
      ...response,
    };

    if (hierarchy) {
      storage.add(HIERARCHY, hierarchy);
    }

    if (checkPaymentIsFinalStatus(response)) {
      saveResponse(hubPayment, action, hierarchy);
      return null;
    }
    return response;
  };

  return {
    onAddPaymentAction,
    setNewResponse,
    saveResponse,
    checkPaymentIsFinalStatus,
    storeData,
    currentCSOperator,
    isAdminInCompanyPays,
    isBusinessProfile,
    userIsAdmin,
  };
};
