import { computed } from 'vue';

import { commonImplementationHelper } from '@Shared/Payments/PaymentMethodsHub/commonImplementationHelper';
import { PaymentMethodsHubComposable } from '@Shared/Payments/PaymentMethodsHub/composables/PaymentMethodsHubComposable';
import { SOURCE_STORE } from '@Shared/Payments/PaymentMethodsHub/constants/paymentMethodsHub';
import { logger } from '@emobg/web-utils';

export const getPaymentMethodsInfo = async (providedStore, providedOperator) => {
  const { storeData } = commonImplementationHelper(providedStore, providedOperator);

  const { getPaymentMethodsCollection, getProfileInfo } = PaymentMethodsHubComposable(storeData);

  const currentProfile = getProfileInfo();

  logger.message('Root: fetching payment methods collection');

  const paymentMethodsCollection = await getPaymentMethodsCollection();

  const payload = {
    profile: currentProfile,
    paymentMethodsCollection,
  };

  await providedStore.dispatch('PaymentMethodsHubStore/savePaymentMethodsCollection', payload);

  await providedStore.dispatch('PaymentMethodsHubStore/updateSource', SOURCE_STORE.root);

  const isPaymentMethodsCollectionEmpty = computed(() => providedStore.getters['PaymentMethodsHubStore/isEmptyPaymentMethodsCollection'](currentProfile)).value;

  const internalPaymentMethods = computed(() => providedStore.getters['PaymentMethodsHubStore/hasAnyInternalPaymentMethod'](currentProfile)).value;

  return {
    paymentMethodsCollection,
    isPaymentMethodsCollectionEmpty,
    internalPaymentMethods,
  };
};
